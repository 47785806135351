<template>
  <div class="ap-main-post">
    <div class="card mb-25">
        <div class="card-body pb-0 px-25 ap-main-post__header">
            <div class="d-flex flex-row pb-20 border-top-0 border-left-0 border-right-0 ap-post-content__title align-items-center ">
                <div class="d-inline-block align-middle mr-3 ml-0 ">
                    <span class=" profile-image bg-opacity-secondary rounded-circle d-flex justify-content-center align-items-center avatar avatar-md m-0" :style="{backgroundColor: avatarColor() }">  {{review.header[0]}} </span>
                </div>
                <h6 class="mb-0 flex-1 text-dark">
                    {{review.header}}
                    <small class="m-0 d-block">
                        {{howLong(review.added)}}
                    </small>
                </h6>
                <!-- <div class="dropdown  dropdown-click ">
                    <button class="btn-link border-0 bg-transparent p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                    </button>
                    <div class="dropdown-default dropdown-bottomRight dropdown-menu">
                        <a class="dropdown-item" href="#">Item One</a>
                        <a class="dropdown-item" href="#">Item Two</a>
                        <a class="dropdown-item" href="#">Item Three</a>
                    </div>
                </div> -->
            </div>
            <div class="image-wrap mb-15" v-if="review.media.uri">
                <img :src="envVariables.VUE_APP_STORAGE_BUCKET + review.media.uri" alt="post image" class="ap-post-attach__headImg w-100 link" @click="$emit('previewImage', {image:[envVariables.VUE_APP_STORAGE_BUCKET + review.media.uri], index: 0})">
            </div>
            <div class="pb-3 border-top-0 border-left-0 border-right-0 ap-post-content__p">
                {{review.comment}}
            </div>
        </div>
    </div>
</div>
</template>

<script>
// import { apiClient } from '@/plugins/restclient';
import {  getColor } from "@/utilities";
import moment from "moment";

export default {
    data(){
        return{
        }
    },
    props:['review'],
    methods:{
        avatarColor(){
            return getColor()
        },
        howLong(time){
            return moment(time).toNow()
        }
    }
}
</script>

<style>
.image-wrap{
    height: 400px;
    overflow: hidden;
    border-radius: 5px;
}
.image-wrap img{
    transition: all 900ms;
    transform: scale(1.03);
}
.image-wrap img:hover{
    transition: all 900ms;
    transform: scale(1.05);
}
</style>