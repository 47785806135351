<template>
  <div class="col-sm-12 col-md-3 mb-25">
        <div class="card  normHeight">
            <div class="card-header d-flex justify-content-between align-items-center py-10  px-sm-25 px-3">
                <h6 class="fw-500 ">Approving Officers</h6>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-center" v-if="approvers.length == 0"> <small>No approval</small> </div>
                <div>
                    <div class="d-flex align-items-center mb-25" v-for="(r, i ) in approvers" :key="i">
                    <span class="wh-46 mr-15 d-flex justify-content-center align-items-center text-white" :style="{backgroundColor: avatarColor()}">{{r.name[0]}}</span>
                    <div>
                        <p class="fs-14 fw-600 color-dark mb-0">{{r.name}}</p>
                        <span class=" mt-1 fs-14  color-light ">{{r.username}}</span>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getColor } from "@/utilities";
export default {
    props:['approvers'],
    methods:{
        avatarColor(){
            return getColor()
        },
    }
}
</script>

<style>

</style>