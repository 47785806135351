<template>
  <div class="contents">
    <SpinOverlay
      :active="!asyncLoader && loadingQuestions"
      style="background:#000, z-index:99999"
      type="fixed"
    />
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="breadcrumb-main p-3 sticky"
            style="background-color: #f4f5f7 !important"
          >
            <h4 class="text-capitalize breadcrumb-title">
              <span
                style="color: #d94f00; cursor: pointer"
                @click="navigateBack"
                ><i class="las la-arrow-left fs-16"></i
              ></span>
              Question Review
              <ul class="atbd-breadcrumb nav">
                <li class="atbd-breadcrumb__item">
                  <router-link to="/">
                    <span class="la la-home"></span>
                  </router-link>
                  <span class="breadcrumb__seperator">
                    <span class="la la-slash"></span>
                  </span>
                </li>
                <li class="atbd-breadcrumb__item">
                  <span class="bread-item" style="color: #9299b8"
                    >Question</span
                  >
                  <span class="breadcrumb__seperator">
                    <span class="la la-slash"></span>
                  </span>
                </li>
                <li class="atbd-breadcrumb__item">
                  <span class="bread-item">Reviews</span>
                </li>
              </ul>
            </h4>

            <div
              class="breadcrumb-action justify-content-center flex-wrap"
              v-if="question"
            >
              <div
                class="action-btn"
                v-if="enableApprove && !(qLevel.status && qLevel.level == 2048)"
                @click="approveQuestion"
              >
                <span
                  class="btn btn-default btn-squared btn-outline-primary link"
                >
                  <i class="la la-plus"></i> Approve Question
                </span>
              </div>
              <div class="action-btn" v-if="publishAble" @click="publishQuestion">
                <span
                  class="btn btn-default btn-squared btn-outline-primary link"
                >
                  <i class="la la-plus"></i> Publish Question
                </span>
              </div>
              <div class="action-btn" v-if="qLevel.status && qLevel.level == 2048" @click="unpublishQuestion">
                <span
                  class="btn btn-default btn-squared btn-outline-primary link"
                >
                  <i class="la la-reply"></i> Unpublish Question
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="projects-tab-content mb-30">
        <div class="row" v-if="question">
          <div class="col-sm-12 col-md-3 mb-25 mw-320">
            <div class="card normHeight">
              <div class="card-body">
                <div class="presets">
                  <span class="hh"> Class </span>
                  <span class="hs">
                    <span class="atbd-tag tag-primary tag-transparented">{{
                      className
                    }}</span>
                  </span>
                  <span class="hh"> Subject </span>
                  <span class="hs">
                    <span class="atbd-tag tag-primary tag-transparented">{{
                      subjectName
                    }}</span>
                  </span>
                  <span class="hh"> Topic </span>
                  <span class="hs">
                    <span class="atbd-tag tag-primary tag-transparented">{{
                      topicName
                    }}</span>
                  </span>
                   <span class="hh"> Scheme</span>
                  <div class="d-flex pl-2">
                    <span
                      class="hs p-0"
                      v-for="(s, k) in question.scheme"
                      :key="k"
                    >
                      <span class="atbd-tag tag-primary tag-transparented">
                        {{ s }}<sup>st</sup> Term</span
                      >
                    </span>
                  </div>
                  <span class="hh"> Question Type </span>
                  <span class="hs">
                    <span class="atbd-tag tag-primary tag-transparented">{{
                      qType(question.type)
                    }}</span>
                  </span>

                 

                  <span class="hh" style="margin-bottom:35px"> Difficulty </span>
                  <span class="hs">
                    <div class="slider-wrapper">
                      <div class="atbd-slider">
                        <div
                          class="
                            slider-basic
                            ui-slider
                            ui-corner-all
                            ui-slider-horizontal
                            ui-widget
                            ui-widget-content
                            ui-slider-disabled
                            ui-state-disabled
                          "
                        >
                          <div
                            class="
                              ui-slider-range
                              ui-corner-all
                              ui-widget-header
                              ui-slider-range-min
                            "
                            :style="{width: difficulty}"
                          ></div>
                          <span
                            tabindex="0"
                            class="
                              ui-slider-handle ui-corner-all ui-state-default
                            "
                            :style="{left: difficulty}"
                            ><span class="tooltip-text" style="display:block">{{parseInt(difficulty)/10}}</span></span>
                        </div>
                      </div>
                    </div>
                  </span>

                  <span class="hh"> Duration </span>
                  <span class="hs">
                    <span class="atbd-tag tag-danger tag-transparented"
                      >{{ question.duration }} Seconds</span
                    >
                  </span>
                </div>
              </div>
            </div>
            <!-- ends: .card -->
          </div>

          <div class="col-sm-12 col-md-6" v-if="funishedQuestion">
            <div class="card mb-25">
              <div class="card-header py-sm-20 py-3 px-sm-25 px-3">
                Question Preview
                <span
                  class="
                    badge badge-round badge-success badge-lg
                    rel-pos
                    appbadge
                  "
                  v-if="publishAble"
                >
                  Approved
                  <span class="badge-mark"><i class="las la-check"></i> </span>
                </span>
              </div>
              <div class="card-body">
                <div class="about-projects">
                  <div class="about-projects__details">
                    <p class="fs-15 mb-25">
                      <span> </span>
                      <span v-for="(q, k) in funishedQuestion" :key="k">
                        <span></span>
                        <span v-if="q.engine == 'text'">
                          <VueMarkdown :source="q.data" />
                        </span>
                        <span v-if="q.engine == 'media'">
                          <span
                            ><img
                              :src="extractMedia(q.assets)"
                              class="img-fluid"
                          /></span>
                          <!-- <div v-else><img :src="q.assets" alt=""></div> -->
                        </span>
                        <span v-if="q.engine == 'latex'">
                          <span v-katex:auto>
                            {{ `\\(${q.data}\\)` }}
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div>
                    <div v-for="(a, k) in answers" :key="k">
                      <AnswerCard
                        :answer="a"
                        :meta="{
                          index: k,
                          label: question.label || 'none',
                          correct: currentCorrectAnswer.indexOf(a.owner) >= 0,
                          rank: '',
                          preview: true,
                        }"
                        :type="question.type"
                        :ref="a.alid"
                      />
                    </div>
                  </div>
                  <!-- <div class="ansTyp">
                                            <h4> Answer <small> </small> </h4>
                                            <ul class="d-flex">
                                                {{currentCorrectAnswer}}
                                                
                                            </ul>
                                        </div> -->
                </div>
                <div class="application-faqs">
                  <div
                    class="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <!-- panel 1 -->
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            class="collapsed"
                          >
                            Solution
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne"
                        class="panel-collapse in show"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                        style=""
                      >
                        <div class="panel-body no-shadow">
                          <span v-for="(s, k) in solution" :key="k">
                            <span></span>
                            <span v-if="s.engine == 'text'">
                              <VueMarkdown :source="s.data" />
                            </span>
                            <span v-if="s.engine == 'media'">
                              <span
                                ><img
                                  :src="extractMedia(s.assets)"
                                  class="img-fluid"
                              /></span>
                              <!-- <div v-else><img :src="s.assets" alt=""></div> -->
                            </span>
                            <span v-if="s.engine == 'latex'">
                              <span v-katex:auto>
                                {{ `\\(${s.data}\\)` }}
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- panel 2 -->
                    <div class="panel panel-default mt-15">
                      <div class="panel-heading" role="tab" id="headingTwo">
                        <h4 class="panel-title">
                          <a
                            class="collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Tip
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseTwo"
                        class="panel-collapse show"
                        role="tabpanel"
                        aria-labelledby="headingTwo"
                        style=""
                      >
                        <div class="panel-body no-shadow">
                          <span v-for="(t, k) in tip" :key="k">
                            <span></span>
                            <span v-if="t.engine == 'text'">
                              <VueMarkdown :source="t.data" />
                            </span>
                            <span v-if="t.engine == 'media'">
                              <span
                                ><img
                                  :src="extractMedia(t.assets)"
                                  class="img-fluid"
                              /></span>
                              <!-- <div v-else><img :src="t.assets" alt=""></div> -->
                            </span>
                            <span v-if="t.engine == 'latex'">
                              <span v-katex:auto>
                                {{ `\\(${t.data}\\)` }}
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <card-comment-form
                :question="question"
                @reviewAdded="reviewAdded"
              ></card-comment-form>
              <card-comment
                :review="r"
                v-for="(r, i) in reviews"
                :key="i"
                @previewImage="previewImage"
              ></card-comment>
            </div>
          </div>
          <reviewers-card
            :approvers="approvers"
            @enablePublish="enablePublish"
            class="mw-320"
          />
        </div>
      </div>
    </div>
    <CoolLightBox
      :items="lightbox.items"
      :index="lightbox.index"
      @close="lightbox.index = null"
    >
    </CoolLightBox>
  </div>
</template>

<script>
import CardComment from "@/components/CardComment";
import CardCommentForm from "@/components/CardCommentForm";
import SpinOverlay from "@/components/SpinOverlay";
import { questionTypeMap } from "@/utilities/constant";
import { getTimestamp } from "@/utilities";
import { apiClient } from "@/plugins/restclient";
import VueMarkdown from "vue-markdown-render";
import ReviewersCard from "@/components/ReviewersCard";
import { arrayFromObject } from "@/utilities";
import { mapState } from "vuex";
import AnswerCard from "@/components/qbuilder/AnswerCard.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    CardComment,
    CardCommentForm,
    VueMarkdown,
    ReviewersCard,
    AnswerCard,
    CoolLightBox,
    SpinOverlay,
  },
  metaInfo: {
    title: 'Greater Favour',
    titleTemplate: '%s | Question Review',
  },
  data() {
    return {
      question: "",
      publishAble: false,
      answers: [],
      currentCorrectAnswer: [],
      funishedQuestion: [],
      reviews: [],
      lightbox: {
        items: [],
        index: null,
      },
      loadingQuestions: false,
      approvers: [],
      solution: [],
      tip: [],
      qLevel: {},
      topicName: ''
    };
  },
  computed: {
    ...mapState(["classes","subjects", "topics", "user", "asyncLoader"]),
    enableApprove() {
      return this.user.ulid != localStorage.getItem("currentQUlid");
    },
    // topicName(){
    //   return ''
    //   // return this.topics.find(i => i.tolid == this.question.tolid)?.name || 'none'
    // },
    subjectName(){
      return this.subjects.find(i => i.sulid == this.question.sulid)?.name || 'none'
    },
    approval() {
      const userPermissions = this.$store.state.user.idiosyncrasy;
      return userPermissions["approval"] == true;
    },
    difficulty(){
      return (this.question.difficulty/10)*100 + '%'
    },
    teacher() {
      const userPermissions = this.$store.state.user.idiosyncrasy;
      return userPermissions["teacher"] == true;
    },
    className() {
      const classes = this.classes.filter(
        (c) => c.cllid == this.question.cllid
      );

      return classes.length ? classes[0].name : "";
    },
  },
  created() {

    this.qLevel = localStorage.getItem('qLevel')
    this.topicName = localStorage.getItem('topicName')
    const currentQlid = localStorage.getItem('currentQlid')
    this.currentQUlid = localStorage.getItem('currentQUlid')
    
    
    this.getAllReviews();
    this.getUserApprovedList(currentQlid);
    this.loadingQuestions = true;
    apiClient
      .post(
        "/v1/office/assessment/question/preview",
        {
          qlid: currentQlid,
        },
        {
          headers: {
            "X-Timestamp": getTimestamp(),
            "X-Ulid": `${this.$store.state.user.ulid}`,
          },
        }
      )
      .then((res) => {
        this.loadingQuestions = false;
        this.question = res.data.info.preview;
        this.funishedQuestion = res.data.info.preview.data.question;
        this.solution = res.data.info.preview.data.solution;
        this.tip = res.data.info.preview.data.tip;

        this.currentCorrectAnswer = this.question.data.answer.split("|");
        if (this.question.type == 16) {
          this.answers = [
            {
              engine: "text",
              data: this.question.data.answer,
              owner: this.question.data.answer,
            },
          ];
        } else {
          this.answers = this.question.data.answers;
        }
      })
      .catch((err) => {
        this.$store.commit("ctoast", {
          type: "danger",
          message: err.response.data.message,
          timeout: 7000,
        });
        this.loadingQuestions = false;
      });
  },
  methods: {
    navigateBack(){
      window.history.length === 1 ? this.$router.replace('/') : this.$router.go(-1)
    },
    extractMedia(assets) {
      if (assets[0]) {
        return assets[0].uri.replace(
          "block://",
          this.envVariables.VUE_APP_STORAGE_BUCKET
        );
      }
    },
    enablePublish() {
      this.publishAble = true;
    },
    qType(type) {
      return questionTypeMap[type];
    },
    previewImage(data) {
      this.lightbox.items = data.image;
      this.lightbox.index = data.index;
    },
    approveQuestion() {
      this.loadingQuestions = true;
      apiClient
        .post("/v1/office/assessment/question/approval/add", {
          owner: this.question.qlid,
        })
        .then((res) => {
          this.loadingQuestions = false;
          this.getUserApprovedList(this.question.qlid);
          this.$store.commit("ctoast", {
            type: "success",
            message: res.data.message,
            timeout: 7000,
          });
          console.log(res.data.info.message);
        })
        .catch((err) => {
          this.loadingQuestions = false;
          this.$store.commit("ctoast", {
            type: "danger",
            message: err.response.data.message,
            timeout: 7000,
          });
        });
    },
    unpublishQuestion(){
      this.loadingQuestions = true
      apiClient.patch('/v1/office/assessment/publish/active', {
        qlid: this.question.qlid,
        active: 0
      }).then(res =>{
        this.$store.commit("ctoast", {
            type: "success",
            message: res.data.message,
            timeout: 7000,
          });
        this.loadingQuestions = false
      }).catch(err =>{
        this.$store.commit("ctoast", {
            type: "danger",
            message: err.response.data.message,
            timeout: 7000,
          });
        this.loadingQuestions = false
      })
    },
    publishQuestion() {
      this.loadingQuestions = true;
      apiClient
        .post(
          "/v1/office/assessment/question/publish",
          {
            qlid: this.question.qlid,
          },
          {
            headers: {
              "X-Timestamp": getTimestamp(),
              "X-Ulid": `${this.$store.state.user.ulid}`,
            },
          }
        )
        .then((res) => {
          this.loadingQuestions = false;
          this.$store.commit("ctoast", {
            type: "success",
            message: res.data.message,
            timeout: 7000,
          });
        })
        .catch((err) => {
          this.loadingQuestions = false;
          this.$store.commit("ctoast", {
            type: "danger",
            message: err.response.data.message,
            timeout: 7000,
          });
        });
    },
    getAllReviews() {
      this.loadingQuestions = true;
      apiClient
        .get(
          `/v1/office/assessment/question/review/fetch/question/${localStorage.getItem(
            "currentQlid"
          )}`,
          {
            headers: {
              "X-Timestamp": getTimestamp(),
              "X-Ulid": `${this.$store.state.user.ulid}`,
            },
          }
        )
        .then((res) => {
          this.reviews = res.data.data;
          this.loadingQuestions = false;
        })
        .catch(() => {
          //   this.getAllReviews()
          this.loadingQuestions = false;
        });
    },
    reviewAdded(res) {
      this.getAllReviews();
      this.$store.commit("ctoast", {
        type: "success",
        message: res.data.message,
        timeout: 7000,
      });
    },
    getUserApprovedList(qlid) {
      apiClient
        .get(`/v1/office/assessment/question/approval/fetch/question/${qlid}`, {
          headers: {
            "X-Timestamp": getTimestamp(),
            "X-Ulid": `${this.$store.state.user.ulid}`,
          },
        })
        .then((res) => {
          this.approvers = arrayFromObject(res.data.info.users);
          if (this.approvers.length >= 3) {
            this.publishAble = true;
          }
        })
        .catch();
    },
  },
};
</script>

<style>
.mw-320 {
  min-width: 320px;
}
.no-shadow {
  box-shadow: none !important;
}
</style>