<template>
  <div class="ap-post-form">
    <div class="card border mb-25">
      <div class="card-header px-md-25 px-3">
        <h6>Post something</h6>
      </div>
      <div class="card-body p-0 px-25">
        <div class="d-flex flex-column">
          <div class="border-0  flex-1 position-relative">
            <div class="pt-20 outline-0 pb-2 pr-0 pl-0 rounded-0 position-relative d-flex border-bottom" tabindex="-1">
              <span class="ap-profile-image bg-opacity-secondary rounded-circle d-flex bg-pos"
                :style="{backgroundImage: `url(${getAvatar($store.state.user)})`}"></span>
              <div class="pl-15 pt-10 flex-1">
                <textarea class="form-control border-0 p-0 fs-xl bg-transparent flex-1" rows="3" v-model="review.content"
                  placeholder="Write something..." spellcheck="false"></textarea>
              </div>
            </div>
          </div>
          <div class="ap-post-attach d-flex flex-row align-items-center flex-wrap flex-shrink-0">
            <input
              type="file"
              name="photo"
              accept=".jpg,.jpeg,.png,.bmp"
              multiple="false"
              id="photo"
              ref="photo"
              @change="fileChange('photo')"
              class="no-display"
            />
            <a class="link rounded-pill" onclick="$('#photo').click()">
              Photo 
            </a>
            <span class="flex-1 ml-10 text-primary"> <span v-if="fileDataHandler">{{fileDataHandler.file.name}} <i class="fa fa-trash" @click="removeFile"></i> </span> </span>
            <a class="link rounded-pill bg-primary text-white" @click="submitReview">
              Post Review
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiClient } from "@/plugins/restclient";
import { getTimestamp } from "@/utilities";
import fileToBase64 from '@/mixins/fileToBase64';
import { mapState } from 'vuex';
export default {
  props:['question'],
  data(){
    return{
      review:{
        content:'',
        media:'',
        engine: 'text',
      },
      fileDataHandler: '',
    }
},
computed:{
  ...mapState(['user'])
},
mixins:[fileToBase64],
  methods:{
    removeFile(){
      this.fileDataHandler = ''
    },
    submitReview(){
      console.log(this.fileDataHandler.file)
      if(this.review.content.trim().length == 0){
        return
      }
          apiClient.post('/v1/office/assessment/question/review/add', {
              owner: this.question.qlid,
              data: this.review.content,
              engine: 'text',
              media: this.fileDataHandler.url
          },{
            headers:{
                "X-Timestamp": getTimestamp(),
                "X-Ulid": `${this.user.ulid}`,
            }
          })
          .then((res) =>{
            this.review.content =''
            this.review.media = ''
            this.review.engine = ''
            this.$emit('reviewAdded', res)
          })
          .catch(err =>{
            this.$store.commit('ctoast',{type:'danger', message: err.response.data.message, timeout: 7000})
          })
      },
  }
}
</script>

<style>

</style>